import React, { useState } from 'react';
import { Row, Col, Image, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import LeadModal from '../../../shared/LeaadModal';
import { motion } from 'framer-motion';
const Integrations = () => {
    const images = [
        '/images/zoom.png',
        '/images/sheet.png',
        '/images/woo.png',
        '/images/insta.png',
        '/images/facebook.png',
        '/images/whatsapp.png',
        '/images/justDail.png',
        '/images/zapier.png',
        '/images/razorpay.png'
    ];
    const [showModal, setShowModal] = useState(false);

    return (
        <Wrapper>
            <Container>
                <Row className="align-items-center mb-5">
                    <Col sm={12} md={6} className="justify-content-center order-md-1 order-1">
                        <h2 className="mt-5 fw-bold">
                            Over 25+ App Integrations
                            <br /> in One Place
                        </h2>
                        <p className="fw-normal grey">
                            Connect with your favorite apps and tools. Whether it's your CRM, email marketing software, or other business tools, our solution
                            integrates seamlessly to streamline your workflow.
                        </p>
                        <Button onClick={() => setShowModal(true)} className="bookDemo">
                            Start Now
                        </Button>
                    </Col>

                    <Col md={6} sm={12} className="order-md-2 order-1 mt-3">
                        <Row>
                            {images.map((src, index) => (
                                <Col key={index} xs={4} className="mb-3">
                                    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                                        <Image className="" src={src} fluid />
                                    </motion.div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <LeadModal title={'Start Now'} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </Wrapper>
    );
};

export default Integrations;

const Wrapper = styled.div`
    font-family: Montserrat;

    .bookDemo {
        background: #012f33;
        color: #fff;
        padding: 10px 20px;
        border-radius: 25px;
        line-height: normal;
        border: 3px solid #012f33;
        font-weight: 600;
        &:hover {
            background: #fff;
            color: #012f33;
        }
    }

    .bookDemo_btn:active {
        background: #012f33;
        border: 3px solid #012f33;
    }

    @media (max-width: 768px) {
        .img-fluid {
            max-width: 80%;
        }
    }
`;
