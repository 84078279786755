import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
const features = [
    {
        imageSrc: '/images/whatsappWorkflow.svg',
        description: 'Build your own WhatsApp Workflows'
    },
    {
        imageSrc: '/images/whatsappAnalytics.svg',
        description: "Campaign & Agent's WhatsApp Analytics"
    },
    {
        imageSrc: '/images/engagement.svg',
        description: 'Real-Time Engagement'
    },
    {
        imageSrc: '/images/data.svg',
        description: 'Own Your Data'
    },
    {
        imageSrc: '/images/inbox.svg',
        description: 'Shared Team Inbox'
    }
];

const Features = () => {
    return (
        <Row className="mx-auto cs_features py-5 justify-content-evenly">
            {features.map((feature, index) => (
                <Col key={index} lg={2} md={6} sm={6}>
                    <div className="w-100">
                        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="text-center">
                            <Image src={feature.imageSrc} fluid />
                            <p className="pt-3 description mx-auto">{feature.description}</p>
                        </motion.div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default Features;
