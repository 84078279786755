import React, { useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import LeadModal from '../../../shared/LeaadModal';

const EarlyBirdOffer = () => {
    const [showModal, setShowModal] = useState(false);
    const modalTitle = 'Get Instant Access';
    const onOpenModal = () => {
        setShowModal(true);
    };
    return (
        <Wrapper>
            <Container>
                <Row>
                    <Col lg={{ span: 4, offset: 3 }} md={{ span: 6, offset: 0 }} className="p-0">
                        {' '}
                        <div className="text-center">
                            <p className=" mb-2 caption">Early Bird Offer</p>
                            <Button className="discount_btn">70% DISCOUNT</Button>
                            <h1 className="m-0  start_today text-dark">Start Today</h1>
                            <h2 className="m-0 text-secondary text-decoration-line-through">Normal Price: $1199</h2>
                            <p className="m-0 pricing">ONLY $499</p>
                            <p className="m-0 text-dark p-0">
                                <b>ONE TIME PAYMENT | NO MONTHLY FEES</b>
                            </p>
                            <Button className=" mt-3 mb-5  rounded get_started" onClick={onOpenModal}>
                                GET INSTANT ACCESS
                            </Button>
                        </div>
                    </Col>
                    <Col lg={{ span: 4, offset: 1 }} md={{ span: 6, offset: 0 }} className="mb-lg-5 mb-md-0">
                        <ul className="mt-xl-5 mt-md-0">
                            <li className="my-2">
                                <p className="m-0">
                                    <b>Client Reports</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>Lead Management Module</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>WhatsApp Automation</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>Live chat</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>24/7 Support</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>Multi user login</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>Unlimited conversation</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>Broadcasting</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>Audience Segmentation</b>
                                </p>
                            </li>
                            <li className="my-2">
                                <p className="m-0">
                                    <b>Shared Team Inbox</b>
                                </p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <LeadModal title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </Wrapper>
    );
};

export default EarlyBirdOffer;

const Wrapper = styled.div`
    background: url('/images/earlyBirdBg.png');
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    padding: 60px;
    .caption {
        font-family: Freestyle Script;
        font-weight: 500;
        font-size: 28px;
        color: #075b51;
    }
    ul {
        list-style: none;
        margin: 0;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.2);
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;
    }

    ul li {
        margin-bottom: 10px;
        padding-left: 40px;
        background-image: url('/images/checkFilled.png');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 25px 25px;
    }

    .discount_btn {
        background-color: #fff;
        color: #22d365;
        border: 2px solid #22d365;
        border-radius: 50px;
        padding: 6px 15px;
        font-weight: 700;
    }

    .start_today {
        font-size: 46px;
    }

    .pricing {
        font-size: 75px;
        font-weight: bold;
        background: linear-gradient(90deg, #1f6465 0%, #50d9db 53.53%, #1f6465 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
    }

    .get_started {
        background: #012f33;
        color: #fff;
        padding: 10px 24px;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 20px;
        border: 1px solid #012f33;
        &:hover {
            background: #fff;
            color: #012f33;
        }
    }

    @media (max-width: 1024px) {
        height: auto;
        padding: 0px;
        background-position: bottom;

        li {
            font-size: 18px;
        }
        .start_today {
            font-size: 38px;
        }

        .pricing {
            font-size: 45px;
        }

        .discount_btn,
        .get_started {
            font-size: 22px;
            padding: 8px 16px;
            // background: #fff;
            // color: #012f33;
            border: 1px solid #012f33;
        }

        .mb-5 {
            margin-bottom: 3rem;
        }
    }

    @media (max-width: 992px) {
        background: none;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        height: auto;
        background: none;
        padding: 0px;
        .start_today {
            font-size: 32px;
        }

        .pricing {
            font-size: 45px;
        }

        .discount_btn,
        .get_started {
            font-size: 18px;
            padding: 8px 16px;
        }

        .mb-5 {
            margin-bottom: 3rem;
        }
    }

    @media (max-width: 576px) {
        height: auto;
        background: none;
        padding: 0px;
        .start_today {
            font-size: 35px;
        }
        .pricing {
            font-size: 50px;
        }
        .discount_btn,
        .get_started {
            font-size: 20px;
            padding: 6px 12px;
        }
    }
`;
