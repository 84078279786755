import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import ModalForm from './ModalForm';

const LeadModal = (props) => {
    const { showModal, onCloseModal, setShowModal, title } = props;
    return (
        <Modal open={showModal} onClose={onCloseModal} center styles={{ modalContainer: { margin: '0 10px' } }}>
            <div className="row g-4 p-md-4 mx-sm-5">
                <div className="col-md-6 d-none d-md-block">
                    {/* <img src="/images/wallet-logo.svg" alt="wallet-logo" className="mb-3" style={{ width: '10rem' }} /> */}
                    <h1 className="text-center">CX</h1>
                    <div className="mb-3">
                        <h2 className="h4 fw-bold mb-3">Take Your Customer Support to the Next Level with Our Platform</h2>
                        <p>Large Enterprises to new-age startups trust us for their Limitless Growth.</p>
                    </div>
                    <img src="/images/formImage.png" alt="formImage" className="mb-4" style={{ width: '18rem' }} />
                </div>
                <div className="col-md-6">
                    <ModalForm setModal2Open={setShowModal} title={title} />
                </div>
            </div>
        </Modal>
    );
};

export default LeadModal;
