import React, { useState } from 'react';
import { Button, Navbar, Nav, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LeadModal from './LeaadModal';
const Header = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const onOpenModal = (title) => {
        setModalTitle(title);
        setShowModal(true);
      };
    const handleNavigation = (event, target) => {
        event.preventDefault();
        const sectionId = target.substring(1);
        const section = document.getElementById(sectionId);
        if (target === '/partner-program') {
            navigate(target);
        } else {
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate(`/${target}`, {
                    state: { scrollToSection: sectionId }
                });
            }
        }
    };

    return (
        <HeaderWrapper>
            <Navbar bg="light" expand="lg" collapseOnSelect>
                <Container>
                    <Navbar.Brand href="/">
                        <p className="m-0 logo ">
                            <b>CX</b>{' '}
                        </p>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Link href="#whyChooseUs" className="text-center fw-bold" onClick={(e) => handleNavigation(e, '#whyChooseUs')}>
                                <span>Why Choose Us</span>
                            </Nav.Link>
                            <Nav.Link href="#features" className="text-center fw-bold" onClick={(e) => handleNavigation(e, '#features')}>
                                <span>Features</span>
                            </Nav.Link>
                            <Nav.Link href="#pricing" className="text-center fw-bold" onClick={(e) => handleNavigation(e, '#pricing')}>
                                <span>Pricing</span>
                            </Nav.Link>
                            <Nav.Link
                                href="/partner-program"
                                className="text-center fw-bold"
                                onClick={(e) => handleNavigation(e, '/partner-program')}
                            >
                                <span>Partner Program</span>
                            </Nav.Link>
                            <Nav.Link href="#bookDemo" className="text-center fw-bold" onClick={() => onOpenModal('Contact Us')}>
                                <span>Contact Us</span>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Button onClick={() => onOpenModal('Book a Demo')} className="tryNow_btn fw-bold ">
                    Book a Demo
                    </Button>
                </Container>
            </Navbar>

            <LeadModal title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </HeaderWrapper>
    );
};

export default Header;

const HeaderWrapper = styled.div`
    font-family: Montserrat;
    .logo {
        font-size: 32px;
    }
    .tryNow_btn {
        font-size: clamp(12px, 4vw, 18px);
        background: #012f33;
        padding: 10px 20px;
        border-radius: 100px;
        border: 3px solid #012f33;
        &:hover {
            background: #fff;
            color: #012f33;
        }
    }
    .tryNow_btn:active {
        background: #012f33;
        color: #fff;
    }

    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .nav-link {
        color: #505050;
        span:hover {
            padding-bottom: 3px;
            border-bottom: 2px solid #012f33;
            color: #012f33;
        }
    }

    @media (max-width: 992px) {
        .navbar-collapse {
            background-color: white;
            position: absolute;
            top: 100%; /* Push the collapse below the navbar */
            left: 0;
            right: 0;
            width: 100%;
        }
        .navbar-toggler {
            order: 1;
        }
        .navbar-brand {
            order: 2;
            margin-left: auto;
            margin-right: auto;
        }
        .tryNow_btn {
            order: 3;
            padding: 2%;
        }
    }

    @media (min-width: 992px) {
        .navbar-toggler {
            display: none;
        }
    }
`;
