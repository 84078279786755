import React from 'react';
import { Col, Container, Row, Card, Stack } from 'react-bootstrap';
import styled from 'styled-components';

const StandOut = () => {
    const info = [
        {
            percent: '86%',
            label: 'Higher Average CSAT Rating'
        },
        {
            percent: '75%',
            label: 'Higher Agent Efficiency'
        },
        {
            percent: '85%',
            label: 'Boost in Customer Engagements'
        }
    ];
    return (
        <Wrapper>
            <h2 className="text-center pt-5">What Makes Us Stand Out</h2>
            <Container className="cs_standout my-5">
                <Row className="p-lg-3 p-sm-2">
                    {info.map((record, index) => (
                        <Col key={index} lg={4} md={4} sm={12} className="mt-3">
                            <Card className="p-lg-4 standout-card">
                                <Stack direction="horizontal" gap={4}>
                                    <h1>{record.percent}</h1>
                                    <p className="card_label text-secondary">{record.label}</p>
                                </Stack>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Wrapper>
    );
};

export default StandOut;

const Wrapper = styled.div`
    .cs_standout {
        border-radius: 28px;
        background: #f6f7f9;
    }

    .standout-card {
        position: relative;
        background: white;
        border-radius: 15px;
        padding: 1rem;

        z-index: 1;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background: linear-gradient(#fff, #fff) padding-box, linear-gradient(165.24deg, #eaedf1 0.15%, #22d364 44.93%, #eaedf1 98.57%) border-box;
        border: 1px solid transparent;
        transition: transform 300ms ease-in-out;
        &:hover {
            transform: scale(1.05);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
    }
    .card_label {
        font-weight: 400;
    }
    @media (min-width: 768px) {
        .container,
        .container-md,
        .container-sm {
            max-width: 840px;
        }
    }
`;
