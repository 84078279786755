import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LeadModal from './LeaadModal';

const Footer = () => {
    const [showModal, setShowModal] = useState(false);
    const modalTitle = "Contact Us";
    const navigate = useNavigate();
    const handleNavigation = (event, target) => {
        event.preventDefault();

        const sectionId = target.substring(1);
        const section = document.getElementById(sectionId);

        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        } else {
            navigate(`/${target}`, {
                state: { scrollToSection: sectionId }
            });
        }
    };
    const onOpenModal = () => {
        setShowModal(true);
    };
    return (
        <Wrapper>
            <Container className="text-light pt-5 pb-4">
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <h2 className="fw-bold">CX</h2>
                        <p>
                            Calls tracking, Live Chat, Whatsapp, CRM, SMS, Email, Facebook Ads, Re-targeting and more all-in-one platform to help your business
                            grow.
                        </p>
                    </Col>
                    <Col lg={4} md={6} sm={12}></Col>
                    <Col lg={4} md={6} sm={12} className="d-none d-lg-block justify-content-end pb-5">
                        <ul className="pt-4">
                            <li className="cursor_pointer" onClick={onOpenModal}>
                                Contact us
                            </li>
                            <li className="cursor_pointer" onClick={(e) => handleNavigation(e, '#pricing')}>
                                Pricing
                            </li>
                            <li>
                                <Link to={'/privacy-policy'} className="text-decoration-none text-light">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to={'/terms-conditions'} className="text-decoration-none text-light">
                                    Terms & Condition
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <hr />
                    <p className=" m-0 text-light">Copyright {new Date().getFullYear()} ©. All right reserved.</p>
                </Row>
            </Container>
            <LeadModal title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </Wrapper>
    );
};

export default Footer;

const Wrapper = styled.div`
    background: #002326;
    font-family: Montserrat;

    li {
        list-style: none;
    }
    .cursor_pointer {
        cursor: pointer;
    s}
    .custom-accordion .accordion-header {
        background: none;
        color: #fff;
        border-bottom: 1px solid #fff;
    }
`;
