import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, Card, Image } from 'react-bootstrap';

const Testinomails = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const data = [
        {
            title: 'Boost in Customer Engagement',
            description:
                'The real-time engagement and automated workflows are game-changers for us. We’ve seen an 85% increase in customer interactions, and it’s all thanks to the seamless integrations with our CRM. We couldn’t ask for a more efficient way to manage our customer communication!',
            user: 'Michael L., CEO',
            icon: '/images/stars.png'
        },
        {
            title: 'Transformative for Our Customer Support',
            description:
                'We implemented the WhatsApp Business API solution from Fab Builder’s CX platform, and the results were immediate. Our response times have improved drastically, and our team is more efficient with the shared inbox feature. Customer satisfaction has soared, and our agents are happier too!',
            user: 'Sarah T., Customer Support Manager',
            icon: '/images/stars.png'
        },
        {
            title: 'Complete Control with Self-Hosting',
            description:
                'Fab Builder’s CX self-hosted solution gave us the control and security we needed. We now manage all our customer data in-house, ensuring privacy while still enjoying the powerful tools like dynamic segmentation and real-time messaging. It’s the best investment we’ve made in our customer experience.',
            user: 'Olivia M., Operations Director ',
            icon: '/images/stars.png'
        },
        {
            title: 'Efficient and Easy-to-Use',
            description:
                'The platform is intuitive and easy to integrate with our existing tools. The analytics dashboard gives us valuable insights, and the multi-channel integration has streamlined our entire workflow. We’ve saved both time and resources!',
            user: 'Ahmed S., Head of Digital ',
            icon: '/images/stars.png'
        },
        {
            title: 'Excellent Customer Support and Value',
            description:
                'From setup to execution, the team at Fab Builder’s CX has been incredibly supportive. Their 24/7 support is top-notch, and the pricing is unbeatable for the features we get. Our agents’ efficiency has increased by 75%, and we couldn’t be happier!',
            user: 'Lisa R., Founder',
            icon: '/images/stars.png'
        }
    ];

    return (
        <Wrapper>
            <div className="pt-3">
                <div className="Testimonials">
                    <h2 className="text-center text-light ">Testimonials</h2>
                </div>
                <h2 className="text-center text-light title">What User Says About Us</h2>
                <Container className="py-5">
                    <Slider {...settings}>
                        {data.map((record, index) => (
                            <CardWrapper key={index}>
                                <Card className="w-100">
                                    <Image className="p-3" src="/images/stars.png" width={128} height={28} fluid />
                                    <Card.Body>
                                        <Card.Title>{record.title}</Card.Title>
                                        <Card.Text className="card_text">{record.description}</Card.Text>

                                        <h6 className="m-0 text-dark">{record.user}</h6>
                                    </Card.Body>
                                </Card>
                            </CardWrapper>
                        ))}
                    </Slider>
                </Container>
            </div>
        </Wrapper>
    );
};

export default Testinomails;

const Wrapper = styled.div`
    background: url('/images/carousel.png');

    background-position: center;
    background-repeat: no-repeat;
    .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: #ffffff;
    }
    .slick-dots li button:before {
        color: #ffffff;
    }
    .Testimonials {
        display: flex;
        justify-content: center;
        h2 {
            border: 3px solid white;
            padding: 12px;
            border-radius: 6px;
        }
    }
    .card-title {
        font-family: 'Instrument Sans', sans-serif !important;
    }
    .title {
        font-size: 45px;
    }
    @media (max-width: 769px) {
        .title {
            padding-top: 30px;
        }
    }
`;

const CardWrapper = styled.div`
    padding: 0 15px;

    .card {
        margin: 0 auto;
    }
    .card-title {
        font-family: Instrument;
        font-weight: 600;
    }
    .card_text {
        height: fit-content;
    }
    // @media (max-width: 769px) {
    //     .card_text {
    //         height: 150px;
    //     }
    // }
`;
